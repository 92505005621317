import useTranslation from "next-translate/useTranslation";
import type { AnswersType } from "@ScreenerSetup/types";
import { Card, formatToLocaleCurrency, Paragraph } from "@boxt/design-system";

import type { Answer, Event, Question } from "@StateMachines/Screener";
import { screener } from "@Locators";
import type { MetaOptions, SchemaAnswer } from "@Pages/api/_next/screeners/types";

import Icon from "../Icon";
import type { IconTheme } from "../Icon/types";

import { CardContainer, Container, IconContainer, Price } from "./styles";

export type Props = {
  answers: SchemaAnswer[];
  onAnswer: (question: Question, answer: Answer, event?: Event | null, option?: MetaOptions) => void;
  question: Question;
  i18nNamespace: string;
  commonIcon?: string;
  isAnswering: boolean;
  isBorderRadiusApplied?: boolean;
  localeKey?: string;
  theme?: IconTheme;
  hasPrice?: boolean;
};

const PriceComponent = ({
  price,
  hasPrice,
  onClick,
}: {
  price: null | string;
  hasPrice: boolean;
  onClick?: () => void;
}) => {
  if (!price || !hasPrice) return null;
  return (
    <Price onClick={onClick}>
      <Paragraph boxtTheme="dark" size="small" weight="bold">
        {price}
      </Paragraph>
    </Price>
  );
};

const Cards = ({
  answers,
  commonIcon,
  hasPrice = true,
  i18nNamespace,
  isAnswering,
  isBorderRadiusApplied,
  onAnswer,
  question,
  theme,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  if (!answers) return null;

  const formatPrice = (price: number | null) => {
    if (price === null) return null;
    if (price === 0) {
      return t("price.free");
    }

    return `+${formatToLocaleCurrency(price)}`;
  };

  return (
    <>
      <Container>
        {answers.map(
          ({
            value: answerValue,
            meta: {
              event = null,
              options = {},
              hasInfo = false,
              isFullWidthIcon = false,
              icon = null,
              localeKey = null,
              price = null,
            },
          }) => {
            const answerKey = localeKey || answerValue;
            return (
              <CardContainer key={answerKey}>
                <PriceComponent
                  hasPrice={hasPrice}
                  onClick={() => onAnswer(question, answerValue, event, options)}
                  price={formatPrice(price)}
                />
                <Card
                  isAnswering={isAnswering}
                  isBorderRadiusApplied={isBorderRadiusApplied}
                  onClick={() => onAnswer(question, answerValue, event, options)}
                  {...(hasInfo && {
                    renderBody: (isInfoOpen) => (
                      <Card.Body isInfoOpen={isInfoOpen}>{t(`answers.${answerKey}.info`)}</Card.Body>
                    ),
                  })}
                  tabIndex={0}
                  testId={`${screener.card}${answerValue}`}
                  withFlair
                >
                  <Card.Image centered={isFullWidthIcon} isStatic={!hasInfo}>
                    <IconContainer>
                      <Icon answer={answerValue as AnswersType} iconName={commonIcon || icon} theme={theme} />
                    </IconContainer>
                  </Card.Image>
                  <Card.Title hasPrice={hasPrice && price !== null}>{t(`answers.${answerKey}.text`)}</Card.Title>
                </Card>
              </CardContainer>
            );
          },
        )}
      </Container>
    </>
  );
};

export default Cards;
