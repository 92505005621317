import type { screenerValuesType } from "@ReduxStore/features/screenerSlice";

import { Products } from "@Collections/products";
import type { StyleConfig } from "@Components/Trans/Trans";
import type { ScreenerVariant } from "@Pages/api/_next/screeners/types";
export const SCREENER_COOKIE_KEY = "boxt_screener_key";

type AnswerType = {
  value: AnswersType | number;
  meta?: Record<string, string | boolean | Record<string, string> | undefined | number>;
};

type AnswerOptions = Record<string, boolean>;

type AnswersProps = {
  state: screenerValuesType;
  options?: AnswerOptions;
};

type Next =
  | QuestionsType
  | null
  | undefined
  | {
      path: string;
      target?: string;
      shallow?: boolean;
      query?: Record<string, string>;
    }
  | ModalNextQuestion;

type ModalNextQuestion = {
  modalName: ScreenerModalType;
  answerValue: AnswersType;
};

type Versions = "base";

type NextProp = {
  value: string;
  state: screenerValuesType;
  options: AnswerOptions;
};

type ValidationType = Record<string, number>;

export const TimeSignature = {
  Is: "is",
  Will: "will",
} as const;

type TimeSignatureType = (typeof TimeSignature)[keyof typeof TimeSignature];

type ScreenerOptions = {
  answerOptions?: AnswerOptions;
  version: Versions;
  variant: ScreenerVariant;
};

type QuestionMeta = {
  assetCollectionName: string | null;
  template: TemplateType | null;
  defaultVisibleItemCount: number | null;
  extraInfoComponent: "bottom-sheet" | "side-drawer";
  questionSuffixKey?: string;
  localeKey?: string;
  hasAdditionalInfo?: boolean;
  hasHelpText?: boolean;
  validations?: ValidationType[];
  hasTimeSignature?: boolean;
  parentQuestion?: QuestionsType;
  styleConfig: StyleConfig;
  isTextArea?: boolean;
};

export const Template = {
  ExpandableList: "expandable_list",
  FilterableList: "filterable_list",
  FreeText: "freetext",
  Info: "info",
  MapBackground: "map_background",
  Postcode: "postcode",
  Redirect: "redirect",
  UnitOfElectricity: "unit_of_electricity",
} as const;

type TemplateType = (typeof Template)[keyof typeof Template];

export const ScreenerModal = { Info: "info", RepairKickout: "repairKickout", SideDrawer: "sideDrawer" } as const;
type ScreenerModalType = (typeof ScreenerModal)[keyof typeof ScreenerModal];

export const Answers = {
  AddToSolar: "add_to_solar",
  AirSource: "air source",
  AiringCupboard: "airing cupboard",
  Alpha: "Alpha",
  AnotherColorCylinder: "another_colour_cylinder",
  April: "april",
  Ariston: "Ariston",
  Atag: "Atag",
  August: "august",
  Average: "average",
  BackBoiler: "back boiler",
  BackOfTheHouse: "back_of_the_house",
  BankOrBuildingSociety: "Bank or building society",
  Basement: "basement",
  Bathroom: "bathroom",
  BatteryOnly: "battery-only",
  Baxi: "Baxi",
  Bedroom: "bedroom",
  Between1900And1949: "1900 - 1949",
  Between1950And1975: "1950 - 1975",
  Between1976And2007: "1976 - 2007",
  Biasi: "Biasi",
  BoilerPlan: "Boiler Plan",
  BritishGas: "British Gas",
  Bungalow: "bungalow",
  CarPort: "car_port",
  Combi: "combi",
  CommercialOrSmallOffice: "commercial-or-small-office",
  ConversionToAiringCupboard: "conversion to airing cupboard",
  CorgiHomePlan: "Corgi HomePlan",
  Cupboard: "cupboard",
  December: "december",
  Detached: "detached",
  DoesNotFitWithOurPlans: "Doesn't fit with our plans",
  DontHaveHomeCover: "I don't have home cover",
  DontKnow: "dont_know",
  Dormer: "dormer",
  Driveway: "driveway",
  ELMLeblance: "ELM Leblance",
  EighteenToThirty: "18 to 30",
  Electric: "electric",
  ElectricBoiler: "electric boiler",
  ElectricRadiators: "electric radiators",
  ExtraLarge: "extra-large",
  Fast: "fast",
  February: "february",
  Ferroli: "Ferroli",
  FirstFloor: "first-floor",
  FivePlus: "five_plus",
  FiveToTenYears: "five_to_ten_years",
  Flat: "flat",
  FloorMounted: "floor-mounted",
  Four: "four",
  FourteenToSixteenRadiators: "14-16 radiators",
  FourthPlusFloor: "4th+ floor",
  Garage: "garage",
  GarageBattery: "garage_battery",
  Gas: "gas",
  GlowWorm: "Glow-worm",
  GreyCylinder: "grey_cylinder",
  Ground: "ground",
  GroundSource: "ground source",
  Heating: "heating",
  HeatingAndHotWater: "heating_and_hot_water",
  Heatline: "Heatline",
  HighestTwoThirds: "highest two-thirds",
  HomeServe: "HomeServe",
  HomeTree: "HomeTree",
  Homeowner: "homeowner",
  HotWater: "hot_water",
  IDontKnow: "I don't know",
  Ideal: "Ideal",
  Inside: "inside",
  InsuranceProvider: "Insurance provider",
  Intergas: "Intergas",
  Jaguar: "Jaguar",
  January: "january",
  JohnsonAndStarley: "Johnson & Starley",
  July: "july",
  June: "june",
  Keston: "Keston",
  Kitchen: "kitchen",
  Landlord: "landlord",
  Large: "large",
  LessThanOneAndHalfMetres: "less than 1.5 metres",
  LessThanTwoHalfMetres: "less-than-two-half-metres",
  LessThanTwoMetres: "less than two metres",
  LivingRoom: "living-room",
  LocalPlumber: "Local plumber / company",
  Loft: "loft",
  LoftOrAttic: "Loft or attic",
  LowestThird: "lowest third",
  Lpg: "lpg",
  Main: "Main",
  March: "march",
  May: "may",
  Medium: "medium",
  MiddleOfTheHouse: "middle of the house",
  MoreThanOneAndHalfMetres: "more than 1.5 metres",
  MoreThanOneRoom: "more-than-one-room",
  MoreThanTwoHalfMetres: "more-than-two-half-metres",
  MoreThanTwoMetres: "more than two metres",
  MoveSomewhereElse: "move somewhere else",
  MoveToAiringCupboard: "move to airing cupboard",
  Myson: "Myson",
  Navien: "Navien",
  No: "no",
  NotMuchSunlight: "not-much-sunlight",
  NotWorking: "Not working",
  November: "november",
  October: "october",
  Oil: "oil",
  OldInefficient: "Old & inefficient",
  OnAnOutsideWall: "on an outside wall",
  One: "one",
  OneFloor: "one floor",
  OneToFive: "1 to 5",
  OneToTwoMeters: "1-2 metres",
  OpenPlanArea: "open-plan-area",
  Other: "other",
  Outside: "outside",
  OverSeventeenRadiators: "Over 17 radiators",
  Pitched: "pitched",
  Post2007: "post 2007",
  Potterton: "Potterton",
  Pre1900: "pre 1900",
  Ravenheat: "Ravenheat",
  Remeha: "Remeha",
  Renting: "renting",
  Roof: "roof",
  Round: "round",
  SameRoom: "same room",
  SaunierDuval: "Saunier Duval",
  SecondOrThirdFloor: "second-or-third-floor",
  SecondThirdFloor: "2nd, 3rd floor",
  SemiDetached: "semi-detached",
  SemiDetachedSolar: "semi_detached",
  September: "september",
  SideOfTheGarage: "side_of_the_garage",
  SideOfTheHouse: "side_of_the_house",
  SixToFifteenMetersEv: "6-15 metres",
  SixToFifteenMetres: "six-to-fifteen-metres",
  SixToNineRadiators: "6-9 radiators",
  SixToTen: "6 to 10",
  SixteenToTwentyFiveMetersEV: "16-25 metres",
  SixteenToTwentyFiveMetres: "sixteen-to-twenty-five-metres",
  Sloped: "sloped",
  Slow: "slow",
  Small: "small",
  SolarPanelsAndBattery: "solar-panels-and-battery",
  SomethingElse: "something_else",
  SomewhereElse: "somewhere else",
  SomewhereElseHeatLink: "somewhere_else",
  SomewhereElseSolar: "somewhere_else",
  Square: "square",
  StandaloneBattery: "standalone_battery",
  StandaloneBatterySolarLater: "standalone_battery_solar_later",
  Standard: "standard",
  SunlightLots: "sunlight-lots",
  SunlightSome: "sunlight-some",
  System: "system",
  TenPlus: "10 plus",
  TenPlusYears: "ten_plus_years",
  TenToThirteenRadiators: "10-13 radiators",
  TenToTwenty: "10 to 20",
  Terrace: "terrace",
  ThirtyOnePlus: "31 plus",
  Three: "three",
  ThreePlus: "3+",
  ThreePlusMeters: "3+ metres",
  ThreeToFiveMeters: "three-to-five-metres",
  TwentyFivePlus: "25 plus",
  TwentyFivePlusEv: "25+",
  TwentyFourSevenHomeRescue: "24/7 Home Rescue",
  TwentyToTwentyFive: "20 to 25",
  Two: "two",
  TwoFloors: "two floors",
  TwoPlus: "2+",
  TwoToFiveYears: "two_to_five_years",
  TwoToThreeMeters: "2-3 metres",
  UnderOneMetre: "Under 1 metre",
  UpToFiveYears: "two_to_five_years",
  UpToOne: "Up to 1",
  UpToSeventeen: "Up to 17",
  UpToTen: "Up to 10",
  UpToTwoYears: "up_to_two_years",
  Utility: "utility",
  UtilityRoom: "utility room",
  Vaillant: "Vaillant",
  Viessmann: "Viessmann",
  Vokera: "Vokera",
  Wall: "wall",
  WallExternal: "wall-external",
  WallInternal: "wall-internal",
  WallMountedHigh: "wall-mounted-high",
  WallMountedLow: "wall-mounted-low",
  Warmflow: "Warmflow",
  WhiteCylinder: "white_cylinder",
  WorcesterBosch: "Worcester Bosch",
  Yes: "yes",
  YourRepair: "YourRepair",
  Zanussi: "Zanussi",
  Zero: "zero",
  ZeroToFiveMetres: "0-5 metres",
  ZeroToFiveRadiators: "0-5 radiators",
} as const;

type AnswersType = (typeof Answers)[keyof typeof Answers];

export const Questions = {
  AcFaultCode: "acFaultCode",
  AcGeneralProblemDescription: "acGeneralProblemDescription",
  AgeOfProperty: "ageOfProperty",
  AnnualElectricityUsage: "annualElectricityUsage",
  BackBoiler: "backBoiler",
  BatteryLocation: "batteryLocation",
  BatteryOnlyOrSolarWithBattery: "batteryOnlyOrSolarWithBattery",
  BatterySpace: "batterySpace",
  BoilerCoAlarmDescription: "boilerCoAlarmDescription",
  BoilerDescription: "boilerDescription",
  BoilerDescriptionBackBoiler: "boilerDescriptionBackBoiler",
  BoilerGasSmellDescription: "boilerGasSmellDescription",
  BoilerGeneralProblemDescription: "boilerGeneralProblemDescription",
  BoilerLocation: "boilerLocation",
  BoilerMake: "boilerMake",
  BoilerServiced: "boilerServiced",
  BoilerServicedMonth: "boilerServicedMonth",
  BoilerType: "boilerType",
  BrokenPlace: "brokenPlace",
  BungalowFloors: "bungalowFloors",
  BuyFromBoxt: "buyFromBoxt",
  COAlarmInfo: "coAlarmInfo",
  ControlHeating: "controlHeating",
  ConvertCombi: "convertCombi",
  CostOfUnitOfElectricity: "costOfUnitOfElectricity",
  CurrentBoilerLocation: "currentBoilerLocation",
  CurrentlyHaveHomecover: "currentlyHaveHomecover",
  CustomBoilerLocation: "customBoilerLocation",
  CustomBrokenPlace: "customBrokenPlace",
  CustomNewAirConLocation: "customNewAirConLocation",
  CustomWhereInstalled: "customWhereInstalled",
  CustomerType: "customerType",
  Distance: "distance",
  ElectricHeatingSystem: "electricHeatingSystem",
  ElectricShower: "electricShower",
  Epc: "epc",
  EpcReference: "epc",
  EvFaultCode: "evFaultCode",
  EvGeneralProblemDescription: "evGeneralProblemDescription",
  ExtraCharges: "extraCharges",
  FaultCode: "faultCode",
  FlatAboveSecondFloor: "flatAboveSecondFloor",
  FlatFloor: "flatFloor",
  FlatWallsAccessible: "flatWallsAccessible",
  FloorArea: "floorArea",
  FlueShape: "flueShape",
  Fuel: "fuel",
  GasLeakInfo: "gasLeakInfo",
  GoogleRef: "googleRef",
  HasShowersOverBath: "hasShowersOverBath",
  HaveMeter: "haveMeter",
  HaveTrvs: "haveTrvs",
  HeatPump: "heatPump",
  HeatingAndHotWaterWorking: "heatingAndHotWaterWorking",
  HeatingUsage: "heatingUsage",
  HomeLocation: "homeLocation",
  HomeType: "homeType",
  HomeownerOrLandlord: "homeownerOrLandlord",
  HotWaterUsage: "hotWaterUsage",
  HowCloseFlueIsToGround: "howCloseFlueIsToGround",
  HowCloseToAnotherPropertyIsYourFlue: "howCloseToAnotherPropertyIsYourFlue",
  HowFarIsBoilerFromWall: "howFarIsBoilerFromWall",
  HowLong: "howLong",
  HowOld: "howOld",
  InsideLocation: "insideLocation",
  InstallType: "installType",
  IsFlueAwayFromDoorOrWindow: "isFlueAwayFromDoorOrWindow",
  IsFlueOnSlopedOrFlatRoof: "isFlueOnSlopedOrFlatRoof",
  IsFlueUnderStructure: "isFlueUnderStructure",
  IsIssueSubmitted: "isIssueSubmitted",
  NeighboursDistance: "neighboursDistance",
  NewAirConLocation: "newAirConLocation",
  NewBoilerLocation: "newBoilerLocation",
  NewBoilerLocationSwap: "newBoilerLocationSwap",
  NewLocation: "newLocation",
  NightRate: "nightRate",
  NumOfBaths: "numOfBaths",
  NumOfBedrooms: "numOfBedrooms",
  NumOfOccupants: "numOfOccupants",
  NumOfPanels: "numOfPanels",
  NumOfRadiators: "numOfRadiators",
  NumOfSeparateShowers: "numOfSeparateShowers",
  NumOfShowersOverBath: "numOfShowersOverBath",
  OffStreetParking: "offStreetParking",
  OrderDetails: "orderDetails",
  OutdoorUnit: "outdoorUnit",
  OutdoorUnitSpace: "outdoorUnitSpace",
  OutsideLocation: "outsideLocation",
  OwnOutdoorGrounds: "ownOutdoorGrounds",
  OwnSolarSystem: "ownSolarSystem",
  PermissionForFlat: "permissionForFlat",
  Postcode: "postcode",
  PowerCuts: "powerCuts",
  PowerShower: "powerShower",
  PressureDropped: "pressureDropped",
  PropertyOwnership: "propertyOwnership",
  PumpSeparated: "pumpSeparated",
  Reference: "reference",
  RoofType: "roofType",
  RoomForCylinder: "roomForCylinder",
  RoomSize: "roomSize",
  ShadeOnRoof: "shadeOnRoof",
  SmartMeter: "smartMeter",
  Sunlight: "sunlight",
  Triage: "triage",
  WallMounted: "wallMounted",
  WallOrLoftInsulation: "wallOrLoftInsulation",
  WallType: "wallType",
  Warranty: "warranty",
  WarrantyYears: "warrantyYears",
  WaterCylinderColor: "waterCylinderColor",
  WaterPressure: "waterPressure",
  WhatIsProblemAc: "whatIsProblemAc",
  WhenDidIssueStart: "whenDidIssueStart",
  WhereBoilerNewLocation: "whereBoilerNewLocation",
  WhereBoilerNewLocationAiringCupboardConversion: "whereBoilerNewLocationAiringCupboardConversion",
  WhereDoesFlueComeOut: "whereDoesFlueComeOut",
  WhereEvInstalled: "whereInstalled",
  WhereInstalled: "whereInstalled",
  WhereInstalledOther: "where_installed_other",
  WhereIsAiringCupboard: "whereIsAiringCupboard",
  WhereOnRoofIsItPositioned: "whereOnRoofIsItPositioned",
  WhichFloor: "whichFloor",
};
type QuestionsType = (typeof Questions)[keyof typeof Questions];

export const DeadEndNods = {
  [Products.AC]: {
    Callout: "/air-conditioning/callout",
  },
  [Products.Boiler]: {
    Callout: "/boilers/callout",
    CalloutOil: "/boilers/callout/oil",
  },
  [Products.BoilerRental]: {
    Callout: "/boilers/callout",
    CalloutOil: "/boilers/callout/oil",
  },
  [Products.EV]: {
    UnableToInstall: "/ev-chargers/unable-to-install",
  },
  [Products.Repair]: {},
  [Products.Heatlink]: {},
  [Products.HomeCover]: {
    CannotCoverFuel: "/home-cover/cannot-cover/fuel",
    CannotCoverOptions: "/home-cover/cannot-cover/options",
  },
  [Products.Solar]: {
    UnableToInstallFlat: "/solar/unable-to-install?issue=flat",
    UnableToInstallFlatRoof: "/solar/unable-to-install?issue=flat-roof",
    UnableToInstallRenting: "/solar/unable-to-install?issue=renting",
  },
  [Products.BatteryOnly]: {
    NoSpace: "/home-battery-storage/no-space",
    Solar: "/solar",
  },
  [Products.HeatPump]: {
    AirSourceCallback: "https://boxt.typeform.com/callback01",
    ElectricBoilerCallback: "https://boxt.typeform.com/callback10",
    ElectricRadiatorsCallback: "https://boxt.typeform.com/callback03",
    GroundSourceCallback: "https://boxt.typeform.com/callback02",
    UnableToInstallFlatOnElevatedFloor: "/heat-pumps/unable-to-install/flat-on-elevated-floor",
    UnableToInstallForCylinder: "/heat-pumps/unable-to-install/no-room-for-cylinder",
    UnableToInstallNoPermission: "/heat-pumps/unable-to-install/no-permission-for-flat",
    UnableToInstallNoSpace: "/heat-pumps/unable-to-install/no-space-for-outdoor-unit",
  },
};
export const queryParamQuestionMapper = {
  [Questions.GoogleRef]: "caseNumber",
};

type DeadEndNodsType = (typeof DeadEndNods)[keyof typeof DeadEndNods];

export type {
  AnswerOptions,
  AnswersProps,
  AnswersType,
  AnswerType,
  DeadEndNodsType,
  ModalNextQuestion,
  Next,
  NextProp,
  QuestionMeta,
  QuestionsType,
  ScreenerModalType,
  ScreenerOptions,
  TimeSignatureType,
  ValidationType,
  Versions,
};
