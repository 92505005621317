import NextTrans from "next-translate/Trans";
import type { FontTheme, PaddingStep, Weight } from "@boxt/design-system";
import { Button, H5, OrderedList, Paragraph, UnorderedList } from "@boxt/design-system";

import { CUSTOMER_SERVICE_PHONE } from "@Collections/client";
import { BulletPoint } from "@Components/pages/common/HelpInfo/styles";
import useChat from "@Hooks/useChat";

import ButtonLink from "./ButtonLink";
import { StyledLi } from "./styles";

export type StyleConfig = {
  [key: string]: {
    [key: string]: string | number;
  };
};

type Align = "center" | "left" | "right";

export type Props = {
  i18nNamespace: string;
  styleConfig?: StyleConfig;
};

const Trans = ({ i18nNamespace, styleConfig }: Props) => {
  const { openChat } = useChat();

  return (
    <NextTrans
      components={{
        // @ts-ignore
        a: <ButtonLink />,

        boxtcustomerservicenumber: (
          <Button boxtTheme="coral" href={`tel://${CUSTOMER_SERVICE_PHONE.replace(/\s/g, "")}`} skin="link">
            {CUSTOMER_SERVICE_PHONE}
          </Button>
        ),
        bp: <BulletPoint $boxtTheme={(styleConfig?.bp?.theme as FontTheme) || "coral"} />,
        br: <br />,
        li: (
          <StyledLi
            $boxtTheme={(styleConfig?.li?.theme as FontTheme) || "coalLighter"}
            $weight={(styleConfig?.li?.weight as Weight) || "regular"}
          />
        ),
        livechat: <Button boxtTheme="coral" onClick={openChat} skin="link" />,
        ol: <OrderedList boxtTheme="coalLighter" />,
        p: (
          <Paragraph
            align={(styleConfig?.paragraph?.align as Align) || "left"}
            as="div"
            bottom={(styleConfig?.paragraph?.bottom as PaddingStep) || 4}
            boxtTheme={(styleConfig?.paragraph?.theme as FontTheme) || "coalLighter"}
            weight={(styleConfig?.paragraph?.weight as Weight) || "regular"}
          />
        ),
        r: <Paragraph as="span" boxtTheme="coral" />,
        s: <strong />,
        span: <span />,
        subtitle: <H5 bottom={4} />,
        ul: <UnorderedList boxtTheme="coalLighter" />,
      }}
      i18nKey={i18nNamespace}
    />
  );
};

export default Trans;
