import { breakpoints, Grid } from "@boxt/design-system";

import NoPaddingGrid from "@Components/layout/NoPaddingGrid";
import Slider from "@Components/pages/common/Slider";
import Trans from "@Components/Trans";
import type { StyleConfig } from "@Components/Trans/Trans";

import SliderItem from "./SliderItem";
import { DotsStyle, SliderContainer, SlideWrapper, StyledCTAButton, StyledGridCol } from "./styles";

type Props = {
  bottomSheetImages: string[];
  ctaButtonText?: string;
  i18nNamespace: string;
  isTextOnly?: boolean;
  onCtaClick?: () => void;
  sliderAltText: string;
  shouldRenderCTAButton: boolean;
  styleConfig?: StyleConfig;
};

const keenSliderOptions = {
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      initial: 0,
      slides: {
        perView: 1,
        spacing: 0,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
    },
  },
};

const BottomSheetContent = ({
  bottomSheetImages,
  ctaButtonText,
  i18nNamespace,
  isTextOnly = false,
  onCtaClick,
  shouldRenderCTAButton,
  sliderAltText,
  styleConfig,
}: Props) => (
  <NoPaddingGrid>
    <Grid.Row>
      <Grid.Col as={StyledGridCol} lg={isTextOnly ? 16 : 8}>
        <Trans i18nNamespace={i18nNamespace} styleConfig={styleConfig} />
        {shouldRenderCTAButton ? (
          <StyledCTAButton boxtTheme="jade" data-testid="extra-info-cta" onClick={onCtaClick}>
            {ctaButtonText}
          </StyledCTAButton>
        ) : null}
      </Grid.Col>
      {!isTextOnly ? (
        <Grid.Col lg={8}>
          {bottomSheetImages && (
            <SliderContainer $hasSingleImage={bottomSheetImages.length === 1}>
              <Slider
                alwaysShowDots
                arrowTheme={{ color: "lighter", position: "inside", size: "small" }}
                controllerStyle={DotsStyle}
                dataTestId="bottom-sheet-content-slider"
                includeSpacing={true}
                showArrows
                sliderOptions={keenSliderOptions}
              >
                {bottomSheetImages.map((imageUrl: string, i) => (
                  <SlideWrapper className={`keen-slider__slide number-slide${i}`} key={`image-slider_${i}`}>
                    <SliderItem altText={sliderAltText} backgroundImage={imageUrl} />
                  </SlideWrapper>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Grid.Col>
      ) : null}
    </Grid.Row>
  </NoPaddingGrid>
);

export default BottomSheetContent;
