import type { FontTheme } from "@boxt/design-system";
import { Paragraph } from "@boxt/design-system";

import type { ArrowPositionType } from "./styles";
import { Container } from "./styles";

export type TooltipPosition = "top" | "bottom";

type Props = {
  text: string;
  bgColor?: string;
  textTheme?: FontTheme;
  position?: TooltipPosition;
  weight?: "regular" | "bold";
  arrowPosition?: ArrowPositionType;
};

const Tooltip = ({
  text,
  textTheme,
  bgColor,
  position = "bottom",
  weight = "regular",
  arrowPosition = "center",
}: Props) => (
  <Container $arrowPosition={arrowPosition} $bgColor={bgColor} $position={position} mh="auto" mt={2}>
    <Paragraph align="center" bottom={4} boxtTheme={textTheme || "dark"} top={4} weight={weight}>
      {text}
    </Paragraph>
  </Container>
);

export default Tooltip;
