export const POSTCODE_REGEX = RegExp(
  /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2}))$/,
);

export const EMAIL_REGEX = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
);

export const UK_DIRECT_DEBIT_REGEX = RegExp(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/);

// check that reference is ORDER_REFERENCE or PLAN_REFERENCE format
export const REFERENCE_REGEX = RegExp(/^(?:[A-Z]{2}-[A-Z0-9]{10}|SB[A-Z0-9]{12})$/);
