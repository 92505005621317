"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, rem, withSpacing } from "@boxt/design-system";

import type { TooltipPosition } from "@Components/utils/Tooltip/Tooltip";

export type ArrowPositionType = "left" | "right" | "center";

const getArrowPosition = ($position: ArrowPositionType) => {
  switch ($position) {
    case "left":
      return "15%";
    case "right":
      return "85%";
    default:
      return "50%";
  }
};

export const Container = withSpacing(styled.div<{
  $bgColor?: string;
  $position: TooltipPosition;
  $arrowPosition: ArrowPositionType;
}>`
  background-color: ${({ $bgColor }) => $bgColor || colors.slate.normal};
  color: ${colors.white};
  position: relative;
  font-weight: 700;
  border-radius: 100px;
  width: fit-content;
  padding: 0 1.5rem;
  box-shadow: 0px 10px 10px rgba(51, 69, 87, 0.08);

  ${breakpoint("lg")} {
    max-width: 36.563rem;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    border: 0.5rem solid transparent;
    left: ${({ $arrowPosition }) => getArrowPosition($arrowPosition)};
    border-top: 0;
    transform: translate(-50%, calc(-100% - 5px));
    ${({ $position, $bgColor }) =>
      $position === "top"
        ? css`
            border-top: 0.5rem solid ${$bgColor || colors.slate.normal};
            bottom: ${rem(-37)};
          `
        : css`
            border-bottom: 0.5rem solid ${$bgColor || colors.slate.normal};
            top: 0.375rem;
          `}
  }
`);
