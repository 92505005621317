"use client";

import styled, { css } from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, colors, H3, H4, Paragraph, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";

const postcodeStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const screenerQuestionPostcodeStyles = css`
  ${postcodeStyles};
  align-items: center;
  max-width: ${rem(800)};
`;

type HighlightTheme = "coral" | "acidGreenDarker";

const QuestionWithSpacingH3 = styled(H3)`
  position: relative;
  padding-top: ${rem(32)};
  ${breakpoint("lg")} {
    padding-top: ${rem(48)};
  }
`;

const QuestionSpan = styled.span``;

const AdditionalInfoParagraph = styled(Paragraph)`
  max-width: ${rem(700)};
  margin: 0 auto;
`;

export const QuestionH3 = withCustomWrapper(
  QuestionWithSpacingH3,
  ({ children, $highlightTheme = "coral" }: { children: string; $highlightTheme: HighlightTheme }) => (
    <H3 as="span" boxtTheme={$highlightTheme} key={children}>
      {children}
    </H3>
  ),
);

export const QuestionH4 = withCustomWrapper(
  QuestionSpan,
  ({ children, $highlightTheme = "coral" }: { children: string; $highlightTheme: HighlightTheme }) => (
    <H4 as="span" boxtTheme={$highlightTheme} key={children}>
      {children}
    </H4>
  ),
);

export const AdditionalInfo = withCustomWrapper(
  AdditionalInfoParagraph,
  ({ children, boxtTheme }: { children: string; boxtTheme: FontTheme }) => (
    <Paragraph as="strong" boxtTheme={boxtTheme} key={children} weight="bold">
      {children}
    </Paragraph>
  ),
);

export const QuestionAndAnswerContainer = styled.div<{ $isPostcode?: boolean; $hasBackgroundImage?: boolean }>`
  position: relative;
  background-color: ${({ $hasBackgroundImage }) => ($hasBackgroundImage ? "transparent" : colors.white)};
  border-radius: ${rem(12)};
  margin-top: ${rem(120)};
  padding-bottom: ${rem(32)};
  ${({ $isPostcode }) => $isPostcode && postcodeStyles}
`;

export const ScreenerQuestionWrapper = styled.div<{ $isPostcode: boolean }>`
  ${({ $isPostcode }) => ($isPostcode ? screenerQuestionPostcodeStyles : null)};
  width: 100%;
`;
