"use client";

import styled from "styled-components";
import { breakpoint, Button, colors, includeSpacing, rem, Spacing } from "@boxt/design-system";

export const Container = styled.div<{ $hasMultipleButtons: boolean }>`
  display: flex;
  width: 90%;
  flex-direction: column;
  ${breakpoint("md")} {
    flex-direction: row;
    justify-content: center;
  }
  ${breakpoint("lg")} {
    justify-content: left;
  }
  ${includeSpacing({ ml: { lg: 5, sm: 0 } })}
  justify-content: center;
  align-items: center;
  button {
    ${breakpoint("lg")} {
      max-width: ${({ $hasMultipleButtons }) => ($hasMultipleButtons ? rem(190) : rem(220))};
    }
  }
`;

export const ButtonWrapper = styled(Spacing)`
  width: 100%;
  ${breakpoint("lg")} {
    width: unset;
  }
`;

export const StartButton = styled(Button)`
  color: ${colors.jade.normal};
`;
